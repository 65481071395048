import React from 'react';
import Headertwo from '../../components/layouts/Header';
import Footertwo from '../../components/layouts/Footer';
import Mainbanner from './components/Mainbanner';
import Bookingform from './components/Bookingform';
import Textblock from './components/Textblock';
import Corefeature from './components/Corefeature';
import Testimonial from './components/Testimonials';
import Map from './components/Map';
import Statistics from "./components/Statistics";
import {withTranslation} from "react-i18next";

const Home = (props) => {

    return (
      <div>
        <Headertwo />

        <Mainbanner/>

        <Bookingform/>

        <Textblock/>

        <Statistics/>

        <Corefeature/>

        <Testimonial/>

        <Map/>

        <Footertwo />
      </div>
    );
  }


export default withTranslation()(Home);
