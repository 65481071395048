import img1 from '../../assets/img/villa-final-photos/1.webp'
import img2 from '../../assets/img/villa-final-photos/2.webp'
import img3 from '../../assets/img/villa-final-photos/3.webp'
import img4 from '../../assets/img/villa-final-photos/4.webp'
import img5 from '../../assets/img/villa-final-photos/5.webp'
import img6 from '../../assets/img/villa-final-photos/6.webp'
import img7 from '../../assets/img/villa-final-photos/7.webp'
import img8 from '../../assets/img/villa-final-photos/8.webp'
import img9 from '../../assets/img/villa-final-photos/9.webp'
import img10 from '../../assets/img/villa-final-photos/10.webp'
import img11 from '../../assets/img/villa-final-photos/11.webp'
import img12 from '../../assets/img/villa-final-photos/12.webp'
import img13 from '../../assets/img/villa-final-photos/13.webp'
import img14 from '../../assets/img/villa-final-photos/14.webp'
import img15 from '../../assets/img/villa-final-photos/15.webp'
import img16 from '../../assets/img/villa-final-photos/16.webp'
import img17 from '../../assets/img/villa-final-photos/17.webp'
import img18 from '../../assets/img/villa-final-photos/18.webp'
import img19 from '../../assets/img/villa-final-photos/19.webp'
import img20 from '../../assets/img/villa-final-photos/20.webp'
import img21 from '../../assets/img/villa-final-photos/21.webp'
import img22 from '../../assets/img/villa-final-photos/22.webp'
import img23 from '../../assets/img/villa-final-photos/23.webp'
import img24 from '../../assets/img/villa-final-photos/24.webp'
import img25 from '../../assets/img/villa-final-photos/25.webp'
import img26 from '../../assets/img/villa-final-photos/26.webp'
import img27 from '../../assets/img/villa-final-photos/27.webp'
import img28 from '../../assets/img/villa-final-photos/28.webp'
import img29 from '../../assets/img/villa-final-photos/29.webp'
import img30 from '../../assets/img/villa-final-photos/30.webp'
import img31 from '../../assets/img/villa-final-photos/31.webp'
import img32 from '../../assets/img/villa-final-photos/32.webp'
import img33 from '../../assets/img/villa-final-photos/33.webp'
import img34 from '../../assets/img/villa-final-photos/34.webp'
import img35 from '../../assets/img/villa-final-photos/35.webp'
import img36 from '../../assets/img/villa-final-photos/36.webp'
import img37 from '../../assets/img/villa-final-photos/37.webp'
import img38 from '../../assets/img/villa-final-photos/38.webp'
import img39 from '../../assets/img/villa-final-photos/39.webp'

export const photos = [
    {
        src: img1,
        width: 3,
        height: 2
    },
    {
        src: img2,
        width: 3,
        height: 2
    },
    {
        src: img3,
        width: 3,
        height: 2
    },
    {
        src: img4,
        width: 3,
        height: 2
    },
    {
        src: img5,
        width: 3,
        height: 2
    },
    {
        src: img6,
        width: 3,
        height: 2,
    },
    {
        src: img7,
        width: 3,
        height: 2
    },
    {
        src: img8,
        width: 3,
        height: 2
    },
    {
        src: img9,
        width: 3,
        height: 2
    },
    {
        src: img10,
        width: 3,
        height: 2
    },
    {
        src: img11,
        width: 3,
        height: 2
    },
    {
        src: img12,
        width: 3,
        height: 2
    },
    {
        src: img13,
        width: 3,
        height: 2
    },
    {
        src: img14,
        width: 3,
        height: 2
    },
    {
        src: img15,
        width: 3,
        height: 2
    },
    {
        src: img16,
        width: 3,
        height: 2
    },
    {
        src: img17,
        width: 3,
        height: 2
    },
    {
        src: img18,
        width: 3,
        height: 2
    },
    {
        src: img19,
        width: 3,
        height: 2
    },
    {
        src: img20,
        width: 3,
        height: 2
    },
    {
        src: img21,
        width: 3,
        height: 2
    },
    {
        src: img22,
        width: 3,
        height: 2
    },
    {
        src: img23,
        width: 3,
        height: 2
    },
    {
        src: img24,
        width: 3,
        height: 2
    },
    {
        src: img25,
        width: 3,
        height: 2
    },
    {
        src: img26,
        width: 3,
        height: 2
    },
    {
        src: img27,
        width: 3,
        height: 2
    },
    {
        src: img28,
        width: 3,
        height: 2
    },
    {
        src: img29,
        width: 3,
        height: 2
    },
    {
        src: img39,
        width: 3,
        height: 2
    },
    {
        src: img30,
        width: 3,
        height: 2
    },
    {
        src: img31,
        width: 3,
        height: 2
    },
    {
        src: img32,
        width: 3,
        height: 2
    },
    {
        src: img33,
        width: 3,
        height: 2
    },
    {
        src: img34,
        width: 3,
        height: 2
    },
    {
        src: img35,
        width: 3,
        height: 2
    },
    {
        src: img36,
        width: 3,
        height: 2
    },
    {
        src: img37,
        width: 3,
        height: 2
    },
    {
        src: img38,
        width: 3,
        height: 2
    }
];
