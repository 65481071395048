import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {withTranslation} from "react-i18next";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
       redText: false
    };
  }
  componentDidMount() {
    window.addEventListener('scroll', () => {
      this.setState({
          isTop: window.scrollY > 300
      });
  }, false);
  }
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  render() {
    const className = this.state.isTop ? 'active' : '';
    return (
      <div>
        {/*====== Back to Top ======*/}
        <Link to="#" className={`back-to-top ${className}`} id="backToTop" onClick={() => this.scrollToTop()}>
          <i className="fal fa-angle-double-up" />
        </Link>
        {/*====== FOOTER START ======*/}
        <footer className="custom-bg">
            <div className="container">
                <div className="row text-center">
                    <div className="py-3 col-12 col-sm-6 align-self-center">
                        <img src="assets/img/logo-white.png" alt="" />
                    </div>
                    <div className="py-3 col-12 col-sm-6 align-self-center">
                        <Link to="/contact" >
                            <button type="button" className="main-btn btn-filled">{this.props.t('Footer.contact-us')}</button>
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
        <div className="bg-black text-center py-1">
            <p className="font-italic font-weight-light text-white-50">&copy; <a href="http://korculastay.com/">KorculaStay</a> all rights reserved</p>
        </div>
        {/*====== FOOTER END ======*/}
      </div>
    );
  }
}

export default withTranslation()(Footer);
