import React, {useState} from 'react';
import {withTranslation} from "react-i18next";
import * as emailjs from "emailjs-com";
import {Modal} from 'react-bootstrap'

const Bookingform = (props) => {

    const [state, setState] = useState({
        showModal: false,
        checkIn:'',
        checkOut:'',
        guests:0,
        name:"",
        email:""
    })

    const handleClose = () =>{
        setState({...state, showModal: false});
        resetForm()
    }

    const handleShow = () =>{
        setState({...state, showModal: true});
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        let templateParams = {
            from_name: state.email,
            to_name: 'info@korculastay.com',
            checkIn: state.checkIn,
            checkOut: state.checkOut,
            guest: state.guests
        }

        emailjs.send(
            'service_46k4494',
            'template_ncw99kh',
            templateParams,
            'user_VIP0h3Kxb3XPjqkxvW1vr'

        )
        resetForm()
        alert("Your request is sent succeessfuly. We will contact you as soon as possible.")
    }
    const resetForm = () => {
        setState({
            showModal: false,
            checkIn:'',
            checkOut:'',
            guests:0,
            name:"",
            email:""
        })
    }

    const handleChange = (e) =>{
        setState({...state, [e.target.name]: e.target.value})
    }

    const renderModal = () => {
        return(
            <Modal
                show={state.showModal}
                onHide={handleClose}
                backdrop="static"
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title><p className="text-uppercase">{props.t('BookingForm.check-availability')}</p></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="contact-form">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="input-group mb-30">
                                        <label htmlFor="arrival-date">{props.t('BookingForm.arrival-date')}</label>
                                        <input required={true} type="date" placeholder="DD-MM-YYYY" name="checkIn" id="arrival-date" value={state.checkIn} onChange={handleChange}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input-group mb-30">
                                        <label htmlFor="departure-date">{props.t('BookingForm.departure-date')}</label>
                                        <input required={true} type="date" placeholder="30-6-2020" name="checkOut" id="departure-date" value={state.checkOut} onChange={handleChange} />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="input-group mb-30">
                                        <label htmlFor="departure-date">{props.t('BookingForm.guests')}</label>
                                        <select required={true} name="guests" id="guest" value={state.guests} onChange={handleChange}>
                                            <option  value="1" > 1 </option>
                                            <option  value="2" > 2 </option>
                                            <option  value="3" > 3 </option>
                                            <option  value="4" > 4 </option>
                                            <option  value="5" > 5 </option>
                                            <option  value="6" > 6 </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="input-group textarea mb-30">
                                        <label htmlFor="arrival-date">Name</label>
                                        <input required={true} type="text" placeholder="Enter your name" name="name" id="name" value={state.name} onChange={handleChange} />

                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="input-group textarea mb-30">
                                        <label htmlFor="arrival-date">Email</label>
                                        <input required={true} type="text" placeholder="Enter your email" name="email" id="email" value={state.email} onChange={handleChange} />

                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    <button type="submit" className="main-btn btn-filled">{props.t('BookingForm.check-availability')}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    return (
        <section className="booking-form">
            <div className="container">
                <div className="booking-form-inner">
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="row align-items-end">
                            <div className="col-lg-3 col-md-6">
                                <div className="inputs-filed mt-30">
                                    <label htmlFor="arrival-date">{props.t('BookingForm.arrival-date')}</label>
                                    <input required={true} type="date"  name="checkIn" value={state.checkIn} onChange={handleChange}
                                           id="arrival-date"/>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="inputs-filed mt-30">
                                    <label htmlFor="departure-date">{props.t('BookingForm.departure-date')}</label>
                                    <input required={true} type="date"  name="checkOut" value={state.checkOut} onChange={handleChange}
                                           id="departure-date"/>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="inputs-filed mt-30">
                                    <label htmlFor="guests">{props.t('BookingForm.guests')}</label>
                                    <div className="nice-select">
                                        <select required={true} name="guests" id="guests" value={state.guests} onChange={handleChange}>
                                            <option value={0}>{props.t('BookingForm.select')}</option>
                                            <option value={1}>1</option>
                                            <option value={2}>2</option>
                                            <option value={3}>3</option>
                                            <option value={4}>4</option>
                                            <option value={5}>5</option>
                                            <option value={6}>6</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="inputs-filed mt-30">
                                    <button onClick={handleShow}>{props.t('BookingForm.check-availability')} </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {renderModal()}
        </section>

    );

}
export default withTranslation()(Bookingform);
