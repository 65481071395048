import React, { Component } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

class About extends Component {
  state = {
    open: false,
    };
    onOpenModal = () => {
    this.setState({ open: true });
    };
    
    onCloseModal = () => {
    this.setState({ open: false });
    };
    render() {
    const { open } = this.state;
    return (
      <section className="about-section pt-115 pb-115">
          <div className="container">
            <div className="video-wrap video-wrap-two video-about mb-60" style={{backgroundImage: 'url(assets/img/text-block/1.webp)'}}>
              <div className="popup-video" onClick={this.onOpenModal} ><i className="fas fa-play" /></div>
              <Modal open={open} onClose={this.onCloseModal} center classNames={{
                  modal: 'video-modal',
                  }}>
                  <iframe src="https://www.youtube.com/embed/ORf7nHdedV8" title="title"></iframe>
                  </Modal>
            </div>
          </div>
        </section>
        
    );
  }
}

export default About;
