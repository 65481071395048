import React, {useState, useCallback} from 'react'
import Headertwo from "../../components/layouts/Header";
import Banner from "../../components/layouts/Banner";
import Footertwo from "../../components/layouts/Footer";
import Gallery from "react-photo-gallery";
import Carousel, {Modal, ModalGateway} from "react-images";
import {photos} from '../../components/helpers/photos'

import {withTranslation} from "react-i18next";

const PhotoGallery = (props) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    return (
        <div>
            <Headertwo/>
            <Banner title={props.t('Banner.gallery')} photo="../../../assets/img/banner/galleryBanner.webp"/>
            <section className="mb-120 mt-120">
                <div className="container hover-cursor">

                    <Gallery photos={photos} onClick={openLightbox} />
                    <ModalGateway>
                        {viewerIsOpen ? (
                            <Modal onClose={closeLightbox}>
                                <Carousel
                                    currentIndex={currentImage}
                                    views={photos.map(img => ({
                                        ...img,
                                        srcset: img.srcSet,
                                        caption: img.title
                                    }))}
                                />
                            </Modal>
                        ) : null}
                    </ModalGateway>
                </div>
            </section>
            <Footertwo/>
        </div>
    );

}

export default withTranslation()(PhotoGallery);
