import React from "react";
import {withTranslation} from "react-i18next";

const Description = (props) =>{
    return (
        <>
            <div className="section-title about-title text-center mt-120 container">
                <h2 className="pb-40">{props.t('Description.title')}</h2>
                <p>{props.t('Description.text')}</p>
            </div>
        </>
    );
}

export default withTranslation()(Description) ;