import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import Preloader from './components/layouts/Preloader';
import Hometwo from './pages/Home/Home';
import About from './pages/About/About';
import ReactGallery from './pages/Gallery/ReactGallery';
import Contact from './pages/Contact/Contact';

const App = () => {
  return (
      <Router>
        { <Preloader />}
        <Switch>
          <Route exact path='/' component={Hometwo} />
          <Route path='/about' component={About} />
          <Route path='/gallery' component={ReactGallery} />
          <Route path='/contact' component={Contact} />
        </Switch>
      </Router>
  );
}

export default App;
