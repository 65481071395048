import React from 'react';
import {Link} from 'react-router-dom';
import ReactWOW from 'react-wow'
import {withTranslation} from "react-i18next";

const Textblock = (props) => {
    return (
        <section className="text-block pt-115 pb-115" id="textblock">
            <div className="container">
                <ReactWOW animation="fadeInLeft" data-wow-delay=".3s">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-7">
                            <div className="text-img text-center text-lg-left mb-small">
                                <img src="assets/img/text-block/tb-01.webp" alt=""/>
                            </div>
                        </div>
                        <ReactWOW animation="fadeInRight" data-wow-delay=".5s">
                            <div className="col-lg-5 col-md-8 col-sm-10">
                                <div className="block-text">
                                    <div className="section-title mb-20">
                                        <span className="title-tag">{props.t('TextBlock.description')}</span>
                                        <h2>{props.t('TextBlock.find-more')}</h2>
                                    </div>
                                    <p>{props.t('TextBlock.big-description')}</p>
                                    <Link to="/about" className="main-btn btn-filled mt-40">{props.t('Mainbanner.btn')}</Link>
                                </div>
                            </div>
                        </ReactWOW>
                    </div>
                </ReactWOW>
            </div>
        </section>

    );

}

export default withTranslation()(Textblock);
