import React from "react"
import Headertwo from '../../components/layouts/Header';
import Banner from "../../components/layouts/Banner";
import Footertwo from "../../components/layouts/Footer";
import emailjs from 'emailjs-com';
import {withTranslation} from "react-i18next";


const Contact = (props) => {

  const handleSubmit = (e) =>{
    e.preventDefault();

    emailjs.sendForm('service_46k4494', 'template_ncw99kh', e.target, 'user_VIP0h3Kxb3XPjqkxvW1vr')
        .then((result) => {
          alert("Your email has been sent!");
        }, (error) => {
          alert("Something went wrong send us email directly!");
        });
  }

    return (
      <div>
        <Headertwo />
        <Banner title={props.t('Banner.contact')} photo="../../../assets/img/banner/contactBanner.webp"/>
        {/*====== CONTACT PART START ======*/}
        <section className="contact-part pt-115 pb-115">
          <div className="container">
            {/* Contact Info */}
            <div className="contact-info">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-sm-6 col-10">
                  <div className="info-box">
                    <div className="icon">
                      <i className="flaticon-home" />
                    </div>
                    <div className="desc">
                      <h4>{props.t('Contact.location')}</h4>
                      <p>Prižba 77, Korčula</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-10">
                  <div className="info-box">
                    <div className="icon">
                      <i className="flaticon-phone" />
                    </div>
                    <div className="desc">
                      <h4>{props.t('Contact.phone')}</h4>
                      <p>+385 99 6964 190</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-10">
                  <div className="info-box">
                    <div className="icon">
                      <i className="flaticon-message" />
                    </div>
                    <div className="desc">
                      <h4>{props.t('email-address')}</h4>
                      <p>info@korculastay.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Contact Form */}
            <div className="contact-form mb-100">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="fName">{props.t("Contact.fname")}</label>
                    <div className="input-group mb-30">
                      <span className="icon"><i className="far fa-user" /></span>
                      <input required={true} type="text" id="fName" name="fName" placeholder={props.t("Contact.fname")} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="lName">{props.t("Contact.lname")}</label>
                    <div className="input-group mb-30">
                      <span className="icon"><i className="far fa-user" /></span>
                      <input required={true} type="text" id="lName" name="lName" placeholder={props.t("Contact.lname")} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="email">{props.t("email-address")}</label>
                    <div className="input-group mb-30">
                      <span className="icon"><i className="far fa-envelope" /></span>
                      <input type="email" id="email" name="email" placeholder={props.t('Contact.email-ph')} required={true}/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="phone">{props.t("Contact.phone")}</label>
                      <div className="input-group mb-30">
                        <span className="icon"><i className="far fa-phone" /></span>
                        <input type="text" id="phone" name="phone" placeholder={props.t('Contact.phone-ph')} />
                      </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="checkIn">{props.t("Contact.checkin")}</label>
                    <div className="input-group mb-30">
                      <span className="icon"><i className="far fa-calendar" /></span>
                      <input required={true} type="date" id="checkIn" name="checkIn" placeholder={props.t("Contact.checkin")} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="checkOut">{props.t("Contact.checkout")}</label>
                    <div className="input-group mb-30">
                      <span className="icon"><i className="far fa-calendar" /></span>
                      <input required={true} type="date" id="checkOut" name="checkOut" placeholder={props.t("Contact.checkout")} />
                    </div>
                  </div>
                  <div className="col-12">
                    <label htmlFor="message">{props.t("Contact.message")}</label>
                    <div className="input-group textarea mb-30">
                      <span className="icon"><i className="far fa-pen" /></span>
                      <textarea required={true} type="text" id="message" placeholder={props.t("Contact.message-ph")} name="message" defaultValue={""} />
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <button type="submit" className="main-btn btn-filled">{props.t("Contact.send")}</button>
                  </div>
                </div>
              </form>
            </div>
            <iframe
                className="map-custom"
                title="title"
                src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Pri%C5%BEba%20Pri%C5%BEba+()&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
            </iframe>
          </div>
        </section>
        {/*====== CONTACT PART END ======*/}
        <Footertwo />
      </div>

    );

}

export default withTranslation()(Contact);
