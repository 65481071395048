import React from "react";
import ReactWOW from "react-wow";
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";

const Statistics = (props) =>{
    return(
        <section className="about-section  pb-115">

        <div className="container">
            <div className="section-title about-title text-center pt-80">
                <h2>{props.t('Statistics.main-info')}</h2>
            </div>
            <ul className="about-features">
                <ReactWOW animation="fadeInUp" data-wow-delay=".3s">
                    <li>
                        <Link to="#">
                            <i className="flaticon-swimming" />
                            <i className="hover-icon flaticon-swimming" />
                            <span className="title">{props.t('Statistics.pool')}</span>
                        </Link>
                    </li></ReactWOW>
                <ReactWOW animation="fadeInUp" data-wow-delay=".4s">
                    <li>
                        <Link to="#">
                            <i className="flaticon-hotel" />
                            <i className="hover-icon flaticon-hotel" />
                            <span className="title">170 m2</span>
                        </Link>
                    </li></ReactWOW>
                <ReactWOW animation="fadeInUp" data-wow-delay=".5s">
                    <li>
                        <Link to="#">
                            <i className="flaticon-bed" />
                            <i className="hover-icon flaticon-bed" />
                            <span className="title">{props.t('Statistics.bedrooms')}</span>
                        </Link>
                    </li></ReactWOW>
                <ReactWOW animation="fadeInUp" data-wow-delay=".6s">
                    <li>
                        <Link to="#">
                            <i className="flaticon-bath" />
                            <i className="hover-icon flaticon-bath" />
                            <span className="title">{props.t('Statistics.bathrooms')}</span>
                        </Link>
                    </li></ReactWOW>
                <ReactWOW animation="fadeInUp" data-wow-delay=".7s">
                    <li>
                        <Link to="#">
                            <i className="flaticon-kitchen" />
                            <i className="hover-icon flaticon-kitchen" />
                            <span className="title">{props.t('Statistics.kitchen')}</span>
                        </Link>
                    </li></ReactWOW>
            </ul>
        </div>
        </section>
    );
}

export default withTranslation()(Statistics);