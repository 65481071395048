import React from 'react';
import { Link } from "react-scroll";
import Slider from "react-slick";
import ReactWOW from 'react-wow'
import {withTranslation} from "react-i18next";

const Mainbanner = (props) => {
    const bannerPosts = [
        {
            tag: props.t('Mainbanner.tag1'),
            taganimation: '.6s',
            title: props.t('Mainbanner.title1'),
            titleanimation: '.9s',
            btn1: props.t('Mainbanner.btn'),
            btn1animation: '1.1s',
            btn1url: '/components',
        },
        {
            tag: props.t('Mainbanner.tag1'),
            taganimation: '.6s',
            title: props.t('Mainbanner.title2'),
            titleanimation: '.9s',
            btn1: props.t('Mainbanner.btn'),
            btn1animation: '1.1s',
            btn1url: '/components',
        },
    ];
        const settings = {
            infinite: true,
            autoplay: true,
            autoplaySpeed: 5000,
            dots: false,
            fade: true,
            arrows: false,
        };
        return (
            <Slider className="banner-area banner-style-two" id="bannerSlider" {...settings}>
                {bannerPosts.map((item, i) => (
                    <div key={i} className="single-banner d-flex align-items-center justify-content-center">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    <div className="banner-content text-center">
                                        <ReactWOW animation="fadeInLeft" data-delay={item.titleanimation}>
                                            <h1 className="title">{item.title}
                                            </h1></ReactWOW>
                                        <ul>
                                            <ReactWOW animation="fadeInUp" data-delay={item.btn1animation}>
                                                <li>
                                                    <Link className="main-btn btn-filled"
                                                          to="textblock" smooth={true} >{item.btn1}</Link>
                                                </li>
                                            </ReactWOW>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* banner bg */}
                        <div className="banner-bg" style={{backgroundImage: 'url(assets/img/banner/banner.webp)'}}/>
                        <div className="banner-overly"/>
                    </div>
                ))}
            </Slider>
        );

}

export default withTranslation()(Mainbanner);