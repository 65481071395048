import React, {Component} from "react";
import {Link} from "react-router-dom";
import $ from "jquery";
import {findDOMNode} from "react-dom";
import LanguageSelector from "../helpers/LanguageSelector";
import {withTranslation} from 'react-i18next';


class Header extends Component {

    navigationmenu = [
        {
            id: 1,
            link: "/",
            linkText: this.props.t('home'),
            child: false,
        },
        {
            id: 2,
            link: "/about",
            linkText: this.props.t('about'),
            child: false,
        },
        {
            id: 3,
            link: "/gallery",
            linkText: this.props.t('gallery'),
            child: false,
        },
        {
            id: 4,
            link: "/contact",
            linkText: this.props.t('contact'),
            child: false,
        },
    ];

    constructor(props) {
        super(props);
        this.state = {
            redText: false,
        };
        this.addClass = this.addClass.bind(this);
        this.removeClass = this.removeClass.bind(this);
        this.removeAll = this.removeAll.bind(this);
    }

    addClass() {
        this.setState({
            redText: true,
        });
    }

    removeClass() {
        this.setState({
            redText: false,
        });
    }

    removeAll() {
        this.setState({
            redText: false,
        });
    }



    componentDidMount() {
        window.addEventListener(
            "resize",
            () => {
                this.setState({
                    isMobile: window.innerWidth < 1020,
                });
            },
            false
        );
        window.addEventListener(
            "load",
            () => {
                this.setState({
                    isMobile: window.innerWidth < 1020,
                });
            },
            false
        );
        window.addEventListener(
            "scroll",
            () => {
                this.setState({
                    isTop: window.scrollY > 150,
                });
            },
            false
        );
    }

    navToggle = () => {
        const nv = findDOMNode(this.refs.navmenu);
        const nvb = findDOMNode(this.refs.navbtn);

        $(nv).toggleClass("menu-on");
        $(nvb).toggleClass("active");

    };
    removenavToggle = () => {
        const nv = findDOMNode(this.refs.navmenu);
        const nvb = findDOMNode(this.refs.navbtn);
        $(nv).removeClass("menu-on");
        $(nvb).removeClass("active");
    };

    render() {
        const className = this.state.isMobile ? "breakpoint-on" : "";
        const classNamess = this.state.isMobile ? "d-none" : "";
        const classNamesss = this.state.isTop ? "sticky-active" : "";
        const classNav = this.state.isTop ? "active-select" : "";

        return (
            <div>
                {/*====== HEADER START ======*/}
                <header
                    className={`header-absolute header-two sticky-header bg-custom ${classNamesss}`}
                >
                    <div className="container container-custom-one">
                        <div
                            className={`nav-container d-flex align-items-center justify-content-between ${className}`}
                        >
                            {/* Main Menu */}
                            <div
                                className="nav-menu d-lg-flex align-items-center"
                                ref="navmenu"
                            >
                                {/* Navbar Close Icon */}
                                <div className="navbar-close" onClick={this.removenavToggle}>
                                    <div className="cross-wrap">
                                        <span className="top"/>
                                        <span className="bottom"/>
                                    </div>
                                </div>
                                {/* Mneu Items */}
                                <div className="menu-items">
                                    <ul>
                                        {this.navigationmenu.length > 0
                                            ? this.navigationmenu.map((item, i) => (
                                                <li key={i} className="">
                                                    <Link to={item.link}> {item.linkText} </Link>
                                                </li>
                                            ))
                                            : null}
                                    </ul>
                                    <div className="language-selector">
                                        <LanguageSelector />
                                    </div>
                                </div>
                                {/* from pushed-item */}
                                <div className="nav-pushed-item"/>
                            </div>
                            {/* Site Logo */}
                            <div className="site-logo">
                                <Link to="/" className="main-logo">
                                    <img src="assets/img/logo-white.png" alt=""/>
                                </Link>
                                <Link to="/" className="sticky-logo">
                                    <img src="assets/img/logo-black.png" alt=""/>
                                </Link>
                            </div>
                            {/* Header Info Pussed To Menu Wrap */}
                            <div className={`nav-push-item ${classNamess}`}>
                                {/* Header Info */}
                                <div className="header-info d-lg-flex align-items-center">
                                    <div className="item">
                                        <i className="fal fa-envelope"/>
                                        <span>{this.props.t('email-address')}</span>
                                        <Link to="/contact">
                                            <h5 className="title">info@korculastay.com</h5>
                                        </Link>
                                    </div>

                                    <div className={`${classNav}`}>
                                        <LanguageSelector/>
                                    </div>

                                </div>
                            </div>
                            {/* Navbar Toggler */}
                            <div
                                className="navbar-toggler"
                                onClick={this.navToggle}
                                ref="navbtn"
                            >


                                <span/>
                                <span/>
                                <span/>
                            </div>
                        </div>
                    </div>
                </header>
                {/*====== HEADER END ======*/}

            </div>
        );
    }
}

export default withTranslation()(Header);
