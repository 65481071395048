import React from 'react';
import Slider from "react-slick";
import {withTranslation} from "react-i18next";
import tesimg1 from '../../../assets/img/testimonial/user.svg';


const Testimonials = (props) => {
  const testimonialPosts = [
    {
      photo: tesimg1,
      name: 'Raymond',
      designation: 'Airbnb',
      desc: props.t('Testimonials.rev1'),
    },
    {
      photo: tesimg1,
      name: 'Heinrich ',
      designation: 'Airbnb',
      desc: props.t('Testimonials.rev2'),
    },
    {
      photo: tesimg1,
      name: 'Leyla',
      designation: 'Airbnb',
      desc: props.t('Testimonials.rev3'),
    },
  ];

    const settings = {
      slidesToShow: 3,
      slidesToScroll: 1,
      fade: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 4000,
      arrows: false,
      dots: true,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
  };
    return (
      <section className="testimonial-section pb-115 pt-115">
          <div className="container">
            <div className="section-title text-center mb-80">
              <span className="title-tag">{props.t('Testimonials.feedback')}</span>
              <h2>{props.t('Testimonials.review')}</h2>
            </div>
            {/* testimonials loop  */}
            <Slider className="row testimonial-slider" {...settings}>
            {testimonialPosts.map((item, i) => (
              <div key={i} className="col-lg-12">
                <div className="testimonial-box">
                  <div className="client-img">
                    <img src={item.photo} alt="" />
                    <span className="check"><i className="fal fa-check" /></span>
                  </div>
                  <h3>{item.name}</h3>
                  <span className="clinet-post">{item.designation}</span>
                  <p>
                  {item.desc}
                  </p>
                </div>
              </div>
              ))}
            </Slider>
          </div>
        </section>
    );

}

export default withTranslation()(Testimonials);
