import React from 'react'
import Aboutpage from './components/About';
import Headertwo from "../../components/layouts/Header";
import Banner from "../../components/layouts/Banner";
import Description from "./components/Description";
import Footertwo from "../../components/layouts/Footer";
import Amenities from "./components/Amenities";
import {withTranslation} from "react-i18next";

const About = (props) => {
    return (
      <div>
        <Headertwo/>
        <Banner title={props.t("Banner.about")} photo="../../../assets/img/banner/aboutBanner.webp"/>
        <Description/>
        <Aboutpage/>
        <Amenities/>
        <Footertwo />
      </div>
    );
}

export default withTranslation()(About);
