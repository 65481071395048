import React from "react";
import {withTranslation} from "react-i18next";

const Amenities = (props) => {
    return (
        <section className="room-details pb-90">
            <div className="container">
                <div className="deatils-box">
                    <div className="room-fearures clearfix mt-60 mb-60">
                        <h3 className="subtitle">{props.t('Amenities.amenities')}</h3>
                        <ul className="room-fearures-list">
                            <li><i className="fal fa-wind"/>{props.t('Amenities.ac')}</li>
                            <li><i className="fal fa-wifi"/>{props.t('Amenities.wifi')}</li>
                            <li><i className="fal fa-utensils"/>{props.t('Amenities.table')}</li>
                            <li><i className="fal fa-toilet-paper"/>{props.t('Amenities.toilet-paper')}</li>
                            <li><i className="fal fa-wind"/>{props.t('Amenities.hairdryer')}</li>
                            <li><i className="fal fa-couch"/>{props.t('Amenities.sofa')}</li>
                            <li><i className="fal fa-shower"/>{props.t('Amenities.shower')}</li>
                            <li><i className="fal fa-headphones-alt"/>{props.t('Amenities.customer-service')}</li>
                            <li><i className="fal fa-bed"/>{props.t('Amenities.bed')}</li>
                            <li><i className="fal fa-bread-slice"/>{props.t('Amenities.toaster')}</li>
                            <li><i className="fal fa-shopping-cart"/>{props.t('Amenities.shop')}</li>
                            <li><i className="fal fa-tv"/>{props.t('Amenities.tv')}</li>
                            <li><i className="fal fa-satellite"/>{props.t('Amenities.satellite')}</li>
                            <li><i className="fal fa-hot-tub"/>{props.t('Amenities.jacuzzi')}</li>
                            <li><i className="fal fa-suitcase-rolling"/>{props.t('Amenities.baggage')}</li>
                            <li><i className="fal fa-smoking-ban"/>{props.t('Amenities.smoking')}</li>
                            <li><i className="fal fa-water"/>{props.t('Amenities.sea-view')}</li>
                            <li><i className="fal fa-umbrella-beach"/>{props.t('Amenities.umbrellas')}</li>
                            <li><i className="fal fa-swimming-pool"/>{props.t('Amenities.pool')}</li>
                            <li><i className="fal fa-parking"/>{props.t('Amenities.parking')}</li>
                            <li><i className="fal fa-paw"/>{props.t('Amenities.pets')}</li>
                        </ul>
                    </div>

                </div>

            </div>
        </section>
    );
}

export default withTranslation()(Amenities);