import React from 'react';
import {withTranslation} from "react-i18next";

const Map = (props) => {
  return (
    <section className="map-section pb-80 pt-115 bg-white">
      <div className="container">
        <div className="section-title text-center mb-80">
          <span className="title-tag">{props.t('Map.location')}</span>
          <h2>{props.t('Map.find-us')}</h2>
        </div>
        {/* Contact Mapts */}
        <div className="mb-30">
          <iframe
              className="map-custom"
              title="title"
              src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Pri%C5%BEba%20Pri%C5%BEba+()&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
          </iframe>
        </div>
      </div>
    </section>
  );
};

export default withTranslation()(Map);
