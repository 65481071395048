import React from 'react';
import Slider from "react-slick";
import ReactWOW from 'react-wow'

const Banner = (props) => {
    const settings = {
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000,
      dots: false,
      fade: true,
      arrows: false,
  };

    const photo = props.photo
    return (
        <Slider className="banner-area banner-style-three" id="bannerSlider" {...settings}>

        <div className="single-banner d-flex align-items-center justify-content-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="banner-content">
                  <ReactWOW animation="fadeInLeft" data-delay='.9s'>
                  <h1 className="title">
                  {props.title}
                  </h1></ReactWOW>

                </div>
              </div>
            </div>
          </div>
          {/* banner bg */}
          <div className="banner-bg" style={{backgroundImage: "url("+ photo + ")"}} />
          <div className="banner-overly" />
        </div>

      </Slider>
      
    );

}

export default Banner;
